<template>
    <section class="main">
      <PageTitle title="Om oss" />
      <section class="dark red">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <img class="scale frame light" src="~@/assets/img/bageriet.jpg">
            </div>
            <div class="col-md-8">
              <h2>Dagmar Carlssons Hembageri Eftr. historia.</h2>
              <p>Dagmar Carlssons Hembageri Eftr. grundades 1935 på Egna Hem i Trollhättan.<br>1937 flyttade bageriet till Kungsgatan mitt inne i centrala Trollhättan.<br>Jan Andersson (VD) började som lärling vid 16 års ålder, och tog sedan över verksamheten 2002<br>tillsammans med Susanne Karlsson.</p>
              <h2>Dagmar Carlssons Hembageri Eftr. målsättning.</h2>
              <p>Vår målsättning är att vara det flexibla kvartersbageriet för alla som uppskattar hantverk av hög kvalité.<br>Vi lägger stor vikt vid att tillverka bakverk utan tillsatser och med de bästa råvarorna.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="content-area light">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <h2 class="underline">Vi som arbetar på Dagmar Carlssons Hembageri Eftr. i Trollhättan.</h2>
              <div class="row">
                <div class="col-md-4 col-sm-4 margin-top">
                  <h2>Jan Andersson</h2>
                  <img class="frame light pull-left" height="80" width="80" src="https://res.cloudinary.com/dagmarcarlssons/image/upload/v1499211314/users/ttqkisvf32exp9x2cix4.jpg">
                  <p>Jan Andersson, född 1962, började på Dagmar Carlssons Hembageri Eftr. som lärling vid 16 års ålder. Efter 24 år inom företaget tog Jan sedan över Dagmar Carlssons Hembageri Eftr. tillsammans med Susanne Karlsson. Dem har nu tillsammans drivit företaget sedan 2002 och brinner för företaget och dess mål att vara det flexibla bageriet/konditoriet.</p>
                </div><div class="col-md-4 col-sm-4 margin-top">
                  <h2>Susanne Karlsson</h2>
                  <img class="frame light pull-left" height="80" width="80" src="https://res.cloudinary.com/dagmarcarlssons/image/upload/v1499211822/users/cfvatrtq4bohqjadam1h.jpg">
                  <p>Susanne Karlsson, född 1964, började på Dagmar Carlssons Hembageri Eftr. år 2000. 2002 tog hon tillsammans med Jan Andersson över Dagmar Carlssons Hembageri Eftr.</p>
                </div><div class="col-md-4 col-sm-4 margin-top">
                  <h2>Marie Grunander</h2>
                  <img class="frame light pull-left" height="80" width="80" src="https://res.cloudinary.com/dagmarcarlssons/image/upload/v1499211866/users/aszg3semkhaj1cw09taa.jpg">
                  <p>Marie Grunander, född 1970, började 1997 som lärling på Princess i Vänersborg. Efter ett år som lärling anställdes hon, och arbetade på Princess i 8 år. I oktober 2006 anställdes Marie som konditor på Dagmar Carlssons Hembageri Eftr. i Trollhättan.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
</template>

<script>
import { PageTitle } from '@/components'

export default {
  components: {
    PageTitle,
  },
  metaInfo: {
    title: 'Information | Bageri & Konditori i Trollhättan | Dagmar Carlssons Hembageri Eftr. | dagmarcarlssons.se',
    description: 'Dagmar Carlssons Hemageri Eftr. har sedan 1937 varit det flexibla kvartersbageriet i Trollhättan. Hos Dagmar Carlssons Hembageri Eftr. finner man goda tårtor och bakelser, men också gott och nyttigt bröd.',
  },
}
</script>

<style></style>
